<template>
	<div class="profile">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />


		<v-form>
			<v-container>
				<v-row class="mb-5">
					<v-col cols="12" sm="8" md="10" lg="8" xl="8" class="mx-auto">
						<v-card class="pa-5 mb-5 mt-2 glassmorphism" :class="$vuetify.theme.dark ? 'dark' : 'light'">

							<v-row>

								<v-col cols="12" sm="12" md="6" lg="6" xl="8">
									<v-col>
										<v-select dark :items="this.$listUtils.civility" v-model="userInfo.civility"
											label="Civilité"></v-select>
									</v-col>

									<v-col>
										<v-text-field dark v-model="userInfo.firstName"
											:rules="this.$fieldsRules.required('prénom')" label="Prénom" required>
										</v-text-field>
									</v-col>

									<v-col>
										<v-text-field dark v-model="userInfo.lastName"
											:rules="this.$fieldsRules.required('nom')" label="Nom" required>
										</v-text-field>
									</v-col>

									<v-col>
										<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
											transition="scale-transition" offset-y min-width="290px">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field dark v-model="formatDate" label="Date de naissance"
													readonly v-bind="attrs" v-on="on">
												</v-text-field>
											</template>
											<v-date-picker first-day-of-week="1" ref="picker"
												v-model="userInfo.birthdate"></v-date-picker>
										</v-menu>
									</v-col>

									<v-col>
										<ImageUpload v-if="userInfo.id" :companyID="userInfo.id" type="self" label="Votre avatar" @newdata="userInfo.avatar=$event"></ImageUpload>
									</v-col>
								</v-col>

								<v-col cols="12" sm="12" md="6" lg="6" xl="4">
									<v-card elevation="16" width="100%" class="pa-2 ma-0 float-right">
										<v-img class="ma-0 pa-0" width="100%" v-if="userInfo.avatar"
											:src="$functions.getImgLink(userInfo.avatar)"></v-img>
										<v-img class="ma-0 pa-0" width="100%" v-else
											src="https://via.placeholder.com/128"></v-img>
									</v-card>


									<v-card-title>
										<v-spacer></v-spacer>
										<v-btn class="ma-5 mt-8" color="white" outlined v-on:click="dialog = false"
											@click="updateProfile">Mettre à jour</v-btn>
										<v-spacer></v-spacer>
									</v-card-title>

								</v-col>


							</v-row>
						</v-card>
					</v-col>



					<v-col cols="12">
						<v-card class="pa-5 mt-2">
							<v-row>
								<v-col cols="12">
									<h3 class="title">Sécurité du compte</h3>
									<v-btn class="my-3 mr-3" outlined @click="dialogs.modifyEmail = true">Modifier son
										email</v-btn>
									<v-btn class="my-3" outlined @click="dialogs.modifyPassword = true">Modifier son mot
										de passe</v-btn>
								</v-col>

								<v-col cols="12">
									<v-divider></v-divider>
								</v-col>

								<v-col cols="12">
									<v-row>
										<v-col cols="12" md="8">
											<h3 class="title">Authentification à double facteur</h3>

											<p class="subtitle-1 success--text"
												v-if="userInfo.otp && userInfo.otp.active">Votre compte est protégé par
												la double authentification.</p>
											<p class="subtitle-1 error--text" v-else>Votre compte n'est pas protégé par
												la double authentification.</p>
										</v-col>

										<v-col cols="12" md="4">
											<div class="float-right">
												<v-btn outlined color="error" @click="deactivateOTP()"
													v-if="userInfo.otp && userInfo.otp.active">Désactiver la double
													authentification</v-btn>
												<v-btn outlined @click="generateOTP()" v-else>Activer la double
													authentification</v-btn>
											</div>
										</v-col>

										<template v-if="checkinOTP">
											<v-col cols="12" sm="8">
												<p>Scannez ce QR Code avec l'application Google Authenticator, puis
													rentrez le code ci-dessous.</p>
												<v-otp-input class="col-6" length="6" v-model="otpcode"
													@finish="checkOTP()" type="number"
													:rules="$fieldsRules.required('code OTP')"></v-otp-input>
												<v-btn outlined @click="checkOTP()">Valider</v-btn>
											</v-col>
											<v-col cols="12" sm="4">
												<v-img :src="otpqr" max-width="200" class="float-right"></v-img>
											</v-col>
										</template>

										<template v-if="!checkinOTP && userInfo.otp && userInfo.otp.backupCodes">
											<v-col cols="12">
												<p>Retrouvez vos code de secours, dans le cas où vous n'auriez pas accès
													à Google Authenticator, ces codes sont valables qu'une seule fois.
												</p>
											</v-col>
											<v-col cols="6" sm="4" v-for="item in backupCodes" :key="item.code">
												<p class="py-0 my-0 privacy" v-bind:class="{'text-decoration-line-through text--secondary': item.used}" @mouseover="obfuscateBackupCodes = false" @mouseleave="obfuscateBackupCodes = true">
													{{ item.code }}</p>
												<p v-if="item.used" class="py-0 my-0 caption privacy">Utilisé le
													{{ $functions.getDateFormat(item.used, 'DD/MM/YYYY')}}</p>
											</v-col>
										</template>
									</v-row>
								</v-col>
							</v-row>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-5 mt-2">
							<v-row>
								<v-col cols="12">
									<v-text-field hint="Votre métier" v-model="userInfo.job" label="Métier">
									</v-text-field>

									<h3 class="title">Entreprises liées à votre compte</h3>
								</v-col>

								<v-col cols="12">
									<v-data-table :headers="headers" :items="companies" :search="search" :item-key="companies.id" single-expand :expanded.sync="expanded" show-expand>

										<template v-slot:[`item.avatar`]="{ item }">

											<v-row align="center" justify="center">
												<v-img v-bind:src="$functions.getImgLink(item.avatar, 200)"
													:lazy-src="$functions.getImgLink(item.avatar, 50)"
													aspect-ratio="1" max-width="50" max-height="50">
													<template v-slot:placeholder>
														<v-row class="fill-height ma-0" align="center" justify="center">
															<v-progress-circular indeterminate :size="50" :width="1"
																color="primary"></v-progress-circular>
														</v-row>
													</template>
												</v-img>
											</v-row>

										</template>

										<template v-slot:[`item.rank`]="{ item }">
											{{ getRank(item.position) }}
										</template>

										<template v-slot:[`item.manage`]="{ item }">
											<v-btn text icon :to="{path: 'record/companies/' + item.id}">
												<v-icon color="primary">mdi-eye</v-icon>
											</v-btn>
											<!--<v-btn text icon @click="syncData">
													<v-icon color="primary">mdi-sync</v-icon>
												</v-btn>-->
											<!--<v-btn text icon @click="preModifyCompany(item.id)">
													<v-icon color="primary">mdi-pencil</v-icon>
												</v-btn>
												<v-btn text icon>
													<v-icon color="primary" @click="openDeleteDialog(item.id)">mdi-delete</v-icon>
												</v-btn>-->
										</template>

										<template v-slot:expanded-item="{ headers, item }">

											<td :colspan="headers.length">
												<div class="row sp-details">
													<div class="col-12 text-left">
														<v-img id="blurMe" height="200px"
															v-bind:src="$functions.getImgLink(item.avatar, 200)"
															style="margin-bottom: -150px;">
														</v-img>
													</div>

													<div class="col-12 text-left">
														<v-card-title>{{ item.name }}</v-card-title>

														<v-card-subtitle>
															{{ item.phone }} • {{ item.email }}
														</v-card-subtitle>

														<v-card-text v-if="item.address.street">
															{{ item.address.street }}
															<br />
															{{ item.address.city }} • {{ item.address.postalCode }}
															<br />
															{{ item.address.region }} @ {{ item.address.country }}
														</v-card-text>
													</div>

												</div>
											</td>

										</template>

									</v-data-table>
								</v-col>

								<v-col cols="12">
									<h3 class="title">Adresse</h3>

									<v-col cols="12" sm="12">
										<v-row>
											<v-col cols="12" sm="6">
												<v-text-field v-model="userInfo.address.street" label="Adresse">
												</v-text-field>
												<v-text-field v-model="userInfo.address.city" label="Ville">
												</v-text-field>
												<v-text-field v-model="userInfo.address.postalCode" label="Code Postal"
													type="number"></v-text-field>
											</v-col>
											<v-col cols="12" sm="6">
												<v-text-field v-model="userInfo.address.region" label="Région">
												</v-text-field>
												<v-text-field v-model="userInfo.address.country" label="Pays">
												</v-text-field>
											</v-col>
										</v-row>
									</v-col>
								</v-col>

								<v-col cols="12">
									<h3 class="title">Contact et détails</h3>

									<v-col cols="12" sm="12">
										<v-text-field label="Telephone" v-model="userInfo.phone"></v-text-field>

										<v-text-field label="Site Web" v-model="userInfo.website"></v-text-field>

										<v-textarea label="Biographie" v-model="userInfo.bio"></v-textarea>
									</v-col>
								</v-col>
							</v-row>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-5 mt-2">
							<v-row>
								<v-col cols="12" class="pb-0">
									<h3 class="title">Mode de communication préféré</h3>

									<v-row>
										<v-col cols="6" sm="4">
											<v-switch v-model="userInfo.contact.mail" flat label="Mail"></v-switch>
										</v-col>
										<v-col cols="6" sm="4">
											<v-switch v-model="userInfo.contact.call" flat label="Appel"></v-switch>
										</v-col>
										<v-col cols="6" sm="4">
											<v-switch v-model="userInfo.contact.sms" flat label="SMS"></v-switch>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" class="pt-0">
									<v-textarea label="Remarque" hint="Remarque sur votre mode de communication favori"
										v-model="userInfo.contact.preference"></v-textarea>
								</v-col>
							</v-row>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-5 mt-2">
							<v-row>
								<v-col cols="12" class="pb-0">
									<h3 class="title pb-3">Notifications</h3>

									<v-row>
										<v-col cols="12" md="6">
											<span>Demande de synchronisation</span>

											<v-row>
												<v-col cols="6" md="4">
													<v-switch v-model="userInfo.notification.sync.mail" flat
														label="Mail"></v-switch>
												</v-col>
												<v-col cols="6" md="4">
													<v-switch v-model="userInfo.notification.sync.app" flat
														label="InApp"></v-switch>
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="12" md="6">
											<span>Réception d'un devis/facture</span>

											<v-row>
												<v-col cols="6" md="4">
													<v-switch v-model="userInfo.notification.quote.mail" flat
														label="Mail"></v-switch>
												</v-col>
												<v-col cols="6" md="4">
													<v-switch v-model="userInfo.notification.quote.app" flat
														label="InApp"></v-switch>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-5 mt-2">
							<v-row>
								<v-col cols="12" class="pb-0">
									<h3 class="title pb-3">Informations Bancaire</h3>

									<v-row>
										<v-col cols="12" md="4">
											<v-switch v-model="userInfo.tva.status" flat label="Assujétti à la TVA">
											</v-switch>
										</v-col>

										<v-col cols="12" md="4" v-if="userInfo.tva.status">
											<v-text-field class="pt-2" label="Numéro TVA"
												hint="Votre numéro de TVA Intracommunautaire"
												v-model="userInfo.tva.number"></v-text-field>
										</v-col>

										<v-col cols="12">
											<v-row>
												<v-col cols="12" sm="4">
													<v-overflow-btn hint="Code pays" dense
														:items="this.$listUtils.bankCountries"
														v-model="userInfo.bank_info.country"></v-overflow-btn>
												</v-col>
												<v-col cols="12" sm="5">
													<v-text-field class="pt-4" label="Banque"
														hint="Entrez le nom de votre banque"
														v-model="userInfo.bank_info.bank"></v-text-field>
												</v-col>
											</v-row>
										</v-col>

										<v-col cols="12">
											<v-text-field label="IBAN / BIC" v-model="userInfo.bank_info.iban">
											</v-text-field>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card>
					</v-col>

					<v-col>
						<v-btn color="success" text outlined @click="updateProfile">Mettre à jour</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-dialog v-model="dialogs.modifyEmail" max-width="700px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Modification de l'adresse email</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="emailForm">
							<v-row>

								<v-col cols="12" sm="6">
									<v-text-field label="Nouvelle adresse email" v-model="modify.email.new"
										:rules="this.$fieldsRules.email.concat(this.$fieldsRules.required('email'))">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field label="Confirmation" hint="Confirmation de la nouvelle adresse email"
										v-model="modify.email.confirmation"
										:rules="$fieldsRules.twoEmails(modify.email.new)"></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field label="Mot de passe" type="password" v-model="modify.opassword"
										:rules="this.$fieldsRules.required('mot de passe')"></v-text-field>
								</v-col>

							</v-row>

						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="modifyEmail()">
						Confirmer
					</v-btn>
					<v-btn color="primary" text @click="dialogs.modifyEmail = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogs.modifyPassword" max-width="700px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Modification du mot de passe</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="passwordForm">
							<v-row>

								<v-col cols="12">
									<v-text-field label="Ancien mot de passe" type="password" v-model="modify.opassword"
										:rules="this.$fieldsRules.required('mot de passe')"></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field label="Nouveau mot de passe" type="password"
										v-model="modify.password.new"
										:rules="this.$fieldsRules.required('mot de passe')"></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field label="Confirmation" hint="Confirmation du nouveau mot de passe"
										type="password" v-model="modify.password.confirmation"
										:rules="this.$fieldsRules.password(modify.password.new).concat(this.$fieldsRules.required('mot de passe'))">
									</v-text-field>
								</v-col>

							</v-row>

						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="modifyPassword()">
						Confirmer
					</v-btn>
					<v-btn color="primary" text @click="dialogs.modifyPassword = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<style scoped>
	.privacy {
		filter: blur(3px);
	}

	.privacy {
		-webkit-animation: privacyOnHover 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: privacyOnHover 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	}

	.privacy:hover {
		-webkit-animation: privacyOutHover 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: privacyOutHover 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	}

	@keyframes privacyOnHover {
		0% {
			filter: blur(3px);
		}

		100% {
			filter: blur(3px);
		}
	}

	@keyframes privacyOutHover {
		0% {
			filter: blur(3px);
		}

		100% {
			filter: blur(0px);
		}
	}

	::selection {
		background-color: rgba(0, 0, 0, 0);
	}

</style>

<script>
	import Head from '@/components/graphics/head';
	const {
		generateSecret,
		verify
	} = require('2fa-util');
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		head: {
			title: {
				inner: "Profil"
			}
		},
		components: {
			Head,
			ImageUpload
		},
		created() {
			this.currentUser = this.$firebase.auth().currentUser;

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.backupCodes = doc.data().otp.backupCodes;
				this.obfuscateBackupCodes = true;

				this.companies = [];

				this.$functions.queryDatabase(`users/${this.userInfo.id}/companies`).then((res) => {
					res.forEach((doc) => {
						this.$db.collection('companies').doc(doc.id).get().then(doc2 => {
							this.companies.push({
								...this.$models.company,
								...doc2.data(),
								...doc.data(),
								id: doc2.id
							});
						});
					})
				});
			});
		},
		data() {
			return {
				userInfo: this.$models.user,

				menu: false,

				dialogs: {
					modifyEmail: false,
					modifyPassword: false
				},

				modify: {
					email: {
						new: '',
						confirmation: '',
					},
					password: {
						new: '',
						confirmation: ''
					},
					opassword: ''
				},

				companies: [],
				expanded: [],
				search: '',
				Head: {

					Title: 'Mon Profil',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				headers: [{
						text: '',
						value: 'avatar'
					},
					{
						text: 'Entreprise',
						value: 'name'
					},
					{
						text: 'SIRET',
						value: 'siret'
					},
					{
						text: 'Position',
						value: 'rank'
					}
				],

				otpcode: '',
				otpqr: '',
				checkinOTP: false,
				backupCodes: [],
				obfuscateBackupCodes: false
			}
		},
		computed: {
			formatDate() {
				return this.userInfo.birthdate ? this.$moment(this.userInfo.birthdate).format('DD/MM/YYYY') : '';
			}
		},
		watch: {
			userInfo: {
				deep: true,
				handler() {
					var tempDate = typeof this.userInfo.birthdate.toDate === "function" ? this.userInfo.birthdate
					.toDate() : this.userInfo.birthdate;

					if (this.userInfo.birthdate) this.userInfo.birthdate = this.$moment(tempDate).format("YYYY-MM-DD");
				}
			},
			obfuscateBackupCodes: function () {
				this.backupCodes = [];

				let back = [];

				if (this.obfuscateBackupCodes) {
					for (let i = 0; i < 6; i++) {
						back.push({
							code: this.$functions.randomNumbers(6),
							used: this.userInfo.otp.backupCodes[i].used
						});
					}

					this.backupCodes = back;
				}
				else this.backupCodes = this.userInfo.otp.backupCodes;
			}
		},
		methods: {
			deactivateOTP() {
				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).set({
					'otp': {}
				}, {
					merge: true
				});
			},
			generateOTP() {
				const optauth = generateSecret(this.userInfo.email, 'Katana');

				function genBackup() {
					let backupCodes = [];

					for (let i = 0; i < 6; i++) {

						backupCodes.push({
							used: false,
							code: Math.floor(Math.random() * (999999 - 111111 + 1) + 111111)
						});

					}

					return backupCodes;
				}

				optauth.then((value) => {
					this.otpqr = value.qrcode;
					this.checkinOTP = true;

					this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).set({
						'otp': {
							'active': false,
							'secret': value.secret,
							'backupCodes': genBackup()
						}
					}, {
						merge: true
					});
				})
			},
			checkOTP() {
				if (!this.otpcode) return;

				const otp = String(this.otpcode);
				const secret = this.userInfo.otp.secret;

				const valid = verify(otp, secret);

				if (valid) {
					this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).update({
						'otp.active': true
					});

					this.checkinOTP = false;
				}
			},

			async modifyPassword() {
				if (!this.$refs.passwordForm.validate()) return;

				if (this.modify.password.new == this.modify.opassword) return this.$store.dispatch('setSnackbar', {
					text: "Le nouveau mot de passe ne peut pas être le même que l'actuel.",
					color: "error"
				});

				const credentials = this.$firebase.auth.EmailAuthProvider.credential(
					this.userInfo.email,
					this.modify.opassword
				);

				this.$firebase.auth().currentUser.reauthenticateWithCredential(credentials).then(() => {
					this.$firebase.auth().currentUser.updatePassword(this.modify.password.new).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: "Le mot de passe à bien été modiié.",
						});

						this.modify.opassword = "";
						this.modify.password = {
							new: '',
							confirmation: ''
						};
						this.dialogs.modifyPassword = false;
					}).catch((error) => {
						this.$store.dispatch('setSnackbar', {
							text: error,
							color: "error"
						});
					});
				}).catch((error) => {
					console.log(error)
					this.$store.dispatch('setSnackbar', {
						text: error,
						color: "error"
					});
				});
			},
			async modifyEmail() {
				if (!this.$refs.emailForm.validate()) return;

				const credentials = this.$firebase.auth.EmailAuthProvider.credential(
					this.userInfo.email,
					this.modify.opassword
				);

				this.$firebase.auth().currentUser.reauthenticateWithCredential(credentials).then(() => {
					this.$firebase.auth().currentUser.updateEmail(this.modify.email.new).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: "L'adresse email à bien été modiiée.",
						});

						this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).set({
							email: this.modify.email.new
						}, {
							merge: true
						});

						this.modify.opassword = "";
						this.dialogs.modifyEmail = false;
					}).catch((error) => {
						this.$store.dispatch('setSnackbar', {
							text: error,
							color: "error"
						});
					});
				}).catch((error) => {
					console.log(error)
					this.$store.dispatch('setSnackbar', {
						text: error,
						color: "error"
					});
				});
			},

			save(date) {
				this.$refs.menu.save(date)
			},

			getRank(rank) {
				return this.$listUtils.rankInCompany[rank]
			},

			getBool(param, color) {
				return this.$cssGlobal.getBool(param, color)
			},
			updateProfile() {
				this.$db.collection('users').doc(this.currentUser.uid).set({
					'civility': this.userInfo.civility,
					'firstName': this.userInfo.firstName,
					'lastName': this.userInfo.lastName,

					'birthdate': new Date(this.userInfo.birthdate),
					'creationDate': new Date(),

					'website': this.userInfo.website,
					'avatar': this.userInfo.avatar,
					'bio': this.userInfo.bio,

					'address': {
						'street': this.userInfo.address.street,
						'city': this.userInfo.address.city,
						'region': this.userInfo.address.region,
						'country': this.userInfo.address.country,
						'postalCode': this.userInfo.address.postalCode
					},

					'phone': this.userInfo.phone,
					'email': this.userInfo.email,

					'job': this.userInfo.job,

					'tva': {
						'status': this.userInfo.tva.status,
						'number': this.userInfo.tva.number
					},

					'bank_info': {
						'country': this.userInfo.bank_info.country,
						'bank': this.userInfo.bank_info.bank,
						'iban': this.userInfo.bank_info.iban
					},

					'contact': {
						'call': this.userInfo.contact.call,
						'mail': this.userInfo.contact.mail,
						'sms': this.userInfo.contact.sms,
						'preference': this.userInfo.contact.preference
					},

					'notification': {
						'sync': {
							'mail': this.userInfo.notification.sync.mail,
							'app': this.userInfo.notification.sync.app
						},
						'quote': {
							'mail': this.userInfo.notification.quote.mail,
							'app': this.userInfo.notification.quote.app
						}
					}
				}, {
					merge: true
				}).then(() => {
					this.$firebase.auth().currentUser.updateProfile({
						photoURL: this.$functions.getImgLink(this.userInfo.avatar, 50)
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: "Votre compte à bien été mis à jour.",
						});
					}).catch((err) => {
						this.$store.dispatch('setSnackbar', {
							text: err,
							timeout: 6000,
							x: 'right',
							y: 'top',
						});
					});
				}).catch((err) => {
					this.$store.dispatch('setSnackbar', {
						text: err,
						color: "error"
					});
				});
			},
			/*syncData(compName, compID) {
				this.$db.collection('customers').doc(this.currentUser.uid).set({
					'civility': this.userInfo.civility,
					'firstName': this.userInfo.firstName,
					'lastName': this.userInfo.lastName,

					'birthdate': this.userInfo.birthdate,
					'creationDate': new Date(),

					'website': this.userInfo.website,
					'avatar': this.userInfo.avatar,
					'bio': this.userInfo.bio,

					'address': {
						'street': this.userInfo.address.street,
						'city': this.userInfo.address.city,
						'region': this.userInfo.address.region,
						'country': this.userInfo.address.country,
						'postalCode': this.userInfo.address.postalCode
					},

					'phone': this.userInfo.phone,
					'email': this.userInfo.email,

					'job': this.userInfo.job,

					'tva': {
						'status': this.userInfo.tva.status,
						'number': this.userInfo.tva.number
					},

					'bank_info': {
						'country': this.userInfo.bank_info.country,
						'bank': this.userInfo.bank_info.bank,
						'iban': this.userInfo.bank_info.iban
					},

					'contact': {
						'call': this.userInfo.contact.call,
						'mail': this.userInfo.contact.mail,
						'sms': this.userInfo.contact.sms,
						'preference': this.userInfo.contact.preference
					},
				}, {
					merge: true
				}).then(() => {
					this.$firebase.auth().currentUser.updateProfile({
						photoURL: this.userInfo.avatar
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Vous avez bien synchronisé vos données avec ${compName}`,
							timeout: 6000,
							x: 'right',
							y: 'top',
						});
					}).catch((err) => {
						this.$store.dispatch('setSnackbar', {
							text: err,
							timeout: 6000,
							x: 'right',
							y: 'top',
						});
					});
				}).catch((err) => {
					this.$store.dispatch('setSnackbar', {
						text: err,
						timeout: 6000,
						x: 'right',
						y: 'top',
					});
				});
			},*/
		}
	}

</script>
